const aceType = {
  EXTRACT_SECTIONS: "EXTRACT_SECTIONS",
  EXTRACT_SECTIONS_COMMIT: "EXTRACT_SECTIONS_COMMIT",
  EXTRACT_SECTIONS_ROLLBACK: "EXTRACT_SECTIONS_ROLLBACK",

  BRD_PARSING: "BRD_PARSING",
  BRD_PARSING_COMMIT: "BRD_PARSING_COMMIT",
  BRD_PARSING_ROLLBACK: "BRD_PARSING_ROLLBACK",

  APPEND_AND_UPLOAD: "APPEND_AND_UPLOAD",
  APPEND_AND_UPLOAD_COMMIT: "APPEND_AND_UPLOAD_COMMIT",
  APPEND_AND_UPLOAD_ROLLBACK: "APPEND_AND_UPLOAD_ROLLBACK",

  RESET_DFC: "RESET_DFC",

  CREATE_ACE_RECORD: "CREATE_ACE_RECORD",
  CREATE_ACE_RECORD_COMMIT: "CREATE_ACE_RECORD_COMMIT",
  CREATE_ACE_RECORD_ROLLBACK: "CREATE_ACE_RECORD_ROLLBACK",

  JIRA_AUTOMATION: "JIRA_AUTOMATION",
  JIRA_AUTOMATION_COMMIT: "JIRA_AUTOMATION_COMMIT",
  JIRA_AUTOMATION_ROLLBACK: "JIRA_AUTOMATION_ROLLBACK",

  ADD_LIMITS_SECTION: "ADD_LIMITS_SECTION",
  ADD_LIMITS_SECTION_COMMIT: "ADD_LIMITS_SECTION_COMMIT",
  ADD_LIMITS_SECTION_ROLLBACK: "ADD_LIMITS_SECTION_ROLLBACK",

  ADD_STANDARDS_SECTION: "ADD_STANDARDS_SECTION",
  ADD_STANDARDS_SECTION_COMMIT: "ADD_STANDARDS_SECTION_COMMIT",
  ADD_STANDARDS_SECTION_ROLLBACK: "ADD_STANDARDS_SECTION_ROLLBACK",

  DOWNLOAD_ACE_DOCUMENT: "DOWNLOAD_ACE_DOCUMENT",
  DOWNLOAD_ACE_DOCUMENT_COMMIT: "DOWNLOAD_ACE_DOCUMENT_COMMIT",
  DOWNLOAD_ACE_DOCUMENT_ROLLBACK: "DOWNLOAD_ACE_DOCUMENT_ROLLBACK",
};

export default aceType;
