import * as React from "react";
import {
  Button,
  ColumnLayout,
  DatePicker,
  Input,
  Select,
} from "@amzn/awsui-components-react";
import {
  Modal,
  Box,
  FormField,
  Button as AlertButton,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  createBuild,
  resetCreateBuild,
} from "../../../redux/actions/build-action";
import {
  createBuildConfig,
  resetCreateBuildConfig,
} from "../../../redux/actions/build-config-action";
import { DEFAULT_BUILD_CONFIG } from "../../../utils/constants";
import constants from "../../../constants";
import { getMetadata } from "../../../redux/actions/metadata-action";
import { createBuildRequest } from "../../../model/http-json";
import { InfoText } from "../info-text/info-text";

interface StateProps {
  metadataReducer: any;
  createBuildReducer: any;
  buildConfigReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  projects: Array<Select.Option>;
  selectedCategory: any;
  categoryError: string; // input error
  selectedProject: any;
  projectError: string; // input error
  build: string; // user input build
  buildError: string; // input error
  version: string; // user input version
  versionError: string; // input error
  startDate: string; // format "YYYY-MM-DD"
  startDateError: string; // input error
  endDate: string; // format "YYYY-MM-DD"
  endDateError: string; // input error
  responseError: string; // response error from BE
  loading: number;
};

class BuildForm extends Component<Props, State> {
  state = {
    projects: [],
    selectedCategory: "",
    categoryError: "",
    selectedProject: "",
    projectError: "",
    build: "",
    buildError: "",
    version: "",
    versionError: "",
    startDate: "",
    startDateError: "",
    endDate: "",
    endDateError: "",
    responseError: "",
    loading: 0,
  };
  _onSubmit = () => {
    if (this.validate()) {
      const createBuildPayload: createBuildRequest = {
        name: this.state.build,
        version: this.state.version,
        start_time: this.state.startDate + " 00:00:00",
        end_time: this.state.endDate + " 00:00:00",
      };
      this.props.dispatch(
        createBuild(
          this.state.selectedCategory,
          this.state.selectedProject,
          createBuildPayload
        )
      );
    }
  };
  _onClear = () => {
    this.setState({
      projects: [],
      selectedCategory: null,
      categoryError: "",
      selectedProject: null,
      projectError: "",
      build: "",
      buildError: "",
      version: "",
      versionError: "",
      startDate: "",
      startDateError: "",
      endDate: "",
      endDateError: "",
      responseError: "",
      loading: 0,
    });
  };
  validate = () => {
    let isValid = true;
    let categoryError = "";
    let projectError = "";
    let buildError = "";
    let versionError = "";
    let startDateError = "";
    let endDateError = "";

    if (!this.state.selectedCategory) {
      isValid = false;
      categoryError = "Required field";
    }
    if (!this.state.selectedProject) {
      isValid = false;
      projectError = "Required field";
    }
    if (!this.state.build) {
      isValid = false;
      buildError = "Required field";
    } else if (!/^[A-Za-z]+$/i.test(this.state.build)) {
      isValid = false;
      buildError = "Letters only";
    } else if (this.state.build.length < 3) {
      isValid = false;
      buildError = "Minimum 3 characters are Required";
    }
    if (!this.state.version) {
      isValid = false;
      versionError = "Required field";
    } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(this.state.version)) {
      isValid = false;
      versionError = "Numbers only";
    }
    if (!this.state.startDate) {
      isValid = false;
      startDateError = "Required field";
    }
    if (!this.state.endDate) {
      isValid = false;
      endDateError = "Required field";
    }
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.startDate.localeCompare(this.state.endDate) > 0
    ) {
      isValid = false;
      endDateError = "End date should be after start date";
    }
    this.setState({
      categoryError,
      projectError,
      buildError,
      versionError,
      startDateError,
      endDateError,
    });
    return isValid;
  };
  _onChangeCategory = (event: any) => {
    let projects: Array<Select.Option> = [];

    const selectedCategory = event.detail.selectedId;

    if (
      this.props.metadataReducer &&
      this.props.metadataReducer.filter &&
      selectedCategory
    ) {
      const categoryItem =
        this.props.metadataReducer.filter[selectedCategory];
      for (let projectId in categoryItem.children) {
        let projectData = categoryItem.children[projectId];
        let project: Select.Option = {
          id: projectId,
          label: projectData.name,
        };
        projects.push(project);
      }
    }
    this.setState({
      selectedCategory,
      categoryError: "",
      projects,
      selectedProject: null,
    });
  };

  getCategoryOptions = (treedata: any) => {
    let optionList: Array<Select.Option> = [];
    for (let categoryId in treedata) {
      let category: Select.Option = {
        id: categoryId,
        label: treedata[categoryId].name,
      };
      optionList.push(category);
    }
    return optionList;
  };

  componentDidUpdate(prevProps: Props) {
    const { dispatch, createBuildReducer } = this.props;
    const { selectedCategory, selectedProject } = this.state;

    const buildLoadingStatusChanged =
      prevProps.createBuildReducer.loadingStatus !==
      createBuildReducer.loadingStatus;
    const buildLoadingSuccess =
      createBuildReducer.loadingStatus === constants.LOADING_SUCCESS;
    const buildId = createBuildReducer.data;

    // create default build config after a build is created
    if (buildLoadingStatusChanged && buildLoadingSuccess && buildId?.length) {
      const payload = { name: DEFAULT_BUILD_CONFIG, is_spot_check: false };
      dispatch(
        createBuildConfig(selectedCategory, selectedProject, buildId, payload)
      );
    }
  }

  reset = (success: boolean) => {
    this.props.dispatch(getMetadata());
    this.props.dispatch(resetCreateBuild());
    this.props.dispatch(resetCreateBuildConfig());
    if (success) this._onClear();
  };

  nextForm = (success: boolean) => {
    this.reset(success);
    // navigate to create testplan
    // this.props.history.push("/build_config/create");
  };

  render() {
    const categoryOptions: Array<Select.Option> =
      this.props.metadataReducer && this.props.metadataReducer.filter
        ? this.getCategoryOptions(this.props.metadataReducer.filter)
        : [];

    const loading = [
      this.props.createBuildReducer.loadingStatus,
      this.props.buildConfigReducer.createLoadingStatus,
    ].includes(constants.LOADING_LOAD);

    const loadingSuccess =
      !loading &&
      this.props.createBuildReducer.loadingStatus === constants.LOADING_SUCCESS;

    const loadingFailed =
      !loading &&
      this.props.createBuildReducer.loadingStatus === constants.LOADING_FAIL;

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Build</h2>
        </div>
        <div>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              <FormField
                label="Category"
                errorText={this.state.categoryError}
                description="* Required"
              >
                <Select
                  options={categoryOptions}
                  placeholder="Category"
                  onChange={this._onChangeCategory}
                  selectedId={this.state.selectedCategory}
                ></Select>
              </FormField>
              <FormField
                label="Project"
                errorText={this.state.projectError}
                description="* Required"
              >
                <Select
                  options={this.state.projects}
                  placeholder="Project"
                  onChange={(event) =>
                    this.setState({
                      selectedProject: event.detail.selectedId,
                      projectError: "",
                    })
                  }
                  selectedId={this.state.selectedProject}
                ></Select>
              </FormField>
            </div>
          </ColumnLayout>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              <FormField
                label="Build Stage"
                constraintText="examples : HVT, EVT, PVT..."
                description="* Required"
                errorText={this.state.buildError}
              >
                <Input
                  onChange={(event) =>
                    this.setState({
                      build: event.detail.value,
                      buildError: "",
                    })
                  }
                  value={this.state.build}
                ></Input>
              </FormField>
              <FormField
                label="Version"
                constraintText="Numbers only"
                description="* Required"
                errorText={this.state.versionError}
              >
                <Input
                  onChange={(event) =>
                    this.setState({
                      version: event.detail.value,
                      versionError: "",
                    })
                  }
                  value={this.state.version}
                ></Input>
              </FormField>
            </div>
          </ColumnLayout>

          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root="true">
              <FormField
                label={
                  <SpaceBetween direction="horizontal" size="xs">
                    {" "}
                    Start Date{" "}
                    <InfoText informatiomText="Start Date of Device" />{" "}
                  </SpaceBetween>
                }
                errorText={this.state.startDateError}
                description="* Required"
              >
                <div className="create-form-date-picker">
                  <DatePicker
                    id="start-date"
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={(event) =>
                      this.setState({
                        startDate: event.detail.value,
                        startDateError: "",
                      })
                    }
                    value={this.state.startDate}
                  ></DatePicker>
                </div>
              </FormField>
              <FormField
                label={
                  <SpaceBetween direction="horizontal" size="xs">
                    {" "}
                    End Date <InfoText informatiomText="End Date of Device" />{" "}
                  </SpaceBetween>
                }
                errorText={this.state.endDateError}
                constraintText="End Date should be after Start Date"
                description="* Required"
              >
                <div className="create-form-date-picker">
                  <DatePicker
                    id="end-date"
                    placeholder="YYYY/MM/DD"
                    todayLabel="Today"
                    nextMonthLabel="Next month"
                    previousMonthLabel="Previous month"
                    onChange={(event) =>
                      this.setState({
                        endDate: event.detail.value,
                        endDateError: "",
                      })
                    }
                    value={this.state.endDate}
                  ></DatePicker>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />
          <br />
          <br />
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this._onClear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              loading={loading}
            >
              Submit
            </Button>
          </div>

          <Modal
            visible={loadingSuccess}
            onDismiss={() => this.reset(true)}
            header="Success"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <AlertButton variant="link" onClick={() => this.reset(true)}>
                    Cancel
                  </AlertButton>
                  <AlertButton
                    variant="primary"
                    onClick={() => this.nextForm(true)}
                  >
                    Ok
                  </AlertButton>
                </SpaceBetween>
              </Box>
            }
          >
            {`${this.props.createBuildReducer.message}.`}
          </Modal>

          <Modal
            visible={loadingFailed}
            onDismiss={() => this.reset(false)}
            header="Failure"
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <AlertButton
                    variant="primary"
                    onClick={() => this.reset(false)}
                  >
                    Ok
                  </AlertButton>
                </SpaceBetween>
              </Box>
            }
          >
            {this.props.createBuildReducer.message}
          </Modal>

          {!loading && this.props.createBuildReducer.message && (
            <label>{this.props.createBuildReducer.message}</label>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
    createBuildReducer: state.createBuildReducer,
    buildConfigReducer: state.buildConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(BuildForm);
