import {CYCLE_DUTY_VALUES, EIRP_PSD_VALUES, JIRA_SECURITY_LEVELS} from "../../../constants";

export function getJiraSecurityLevels(){
    return JIRA_SECURITY_LEVELS.map(level => {
        return {
            label: level,
            value: level
        }
    });
}

export function getCycleDutyValues(){
    return CYCLE_DUTY_VALUES.map(level => {
        return {
            label: level,
            value: level
        }
    });
}

export function getEirpPsdValues(){
    return EIRP_PSD_VALUES.map(level => {
        return {
            label: level,
            value: level
        }
    });
}

export function getAceFileName(aceId){
    return `${aceId}.docx`;
}