import React, {useEffect, useMemo} from "react";
import {Box, Button} from "@amzn/awsui-components-react-v3";
import constants from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {downloadAceDocument} from "../../../redux/actions/ace-action";
import {getAceFileName} from "./ace-utils";


function AceDownloadSection(
  {
    aceId
  }) {
  const dispatch = useDispatch();
  const aceReducer = useSelector((state: any) => state.aceReducer);

  const aceFileName = useMemo(() => getAceFileName(aceId)
    , [aceId]);

  const onDownloadButtonClick = () => {
    dispatch(downloadAceDocument(aceFileName));
  };

  useEffect(() => {
    if (aceReducer.downloadAceDocumentLoadingStatus === constants.LOADING_SUCCESS) {
      const url = aceReducer.downloadAceDocumentResponse?.url;
      if (!url) {
        return;
      }
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", aceFileName);
      document.body.appendChild(link);
      link.click();
    }
  }, [aceReducer.downloadAceDocumentLoadingStatus]);

  return (
    <div>
      <Button
        variant="primary"
        onClick={() => onDownloadButtonClick()}
      >
        Download ACE document
      </Button>
      {
        aceReducer.downloadAceDocumentLoadingStatus === constants.LOADING_LOAD &&
          <Box
              color={"text-status-info"}
          >
              Downloading ACE document ...
          </Box>
      }
      {
        aceReducer.downloadAceDocumentLoadingStatus === constants.LOADING_SUCCESS &&
          <Box
              color={"text-status-success"}
          >
              Downloaded ACE document
          </Box>
      }
      {
        aceReducer.downloadAceDocumentLoadingStatus === constants.LOADING_FAIL &&
          <Box
              color={"text-status-error"}
          >
              Download failed. Please try again.
          </Box>
      }
    </div>
  );
}

export default AceDownloadSection;
