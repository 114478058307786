import endpoints from "../../endpoints/endpoints";
import {config} from "../../config/config";
import constants, {METRIC_INFO} from "../../constants";
import aceType from "../types/ace-type";

export const extractSections = ({bucket_name, file_name}) => ({
  type: aceType.EXTRACT_SECTIONS,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.extractSections(),
        method: "POST",
        json: {bucket_name, file_name},
      },
      commit: {
        type: aceType.EXTRACT_SECTIONS_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.EXTRACT_SECTIONS_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const brdParsing = ({
                             doc_type,
                             doc_name,
                             section_names,
                             dfc_doc_type,
                             dfc_doc_name,
                           }) => {
  const requestBody = {
    doc_type,
    doc_name,
    section_names,
    dfc_doc_type,
    dfc_doc_name,
  };

  const log = {
    initiative: constants.INITIATIVE_ACE,
    feature: METRIC_INFO.ACE,
  };

  return {
    type: aceType.BRD_PARSING,
    http: {
      cognito: {
        effect: {
          url: config.BASE_URL + endpoints.brdParsing(),
          method: "POST",
          json: requestBody,
        },
        commit: {
          type: aceType.BRD_PARSING_COMMIT,
          log,
          requestBody,
        },
        rollback: {
          type: aceType.BRD_PARSING_ROLLBACK,
          log,
          requestBody,
        },
      },
    },
  };
};

export const appendTextAndUploadDoc = ({
                                         bucket_name,
                                         file_name,
                                         text_to_add,
                                       }) => ({
  type: aceType.APPEND_AND_UPLOAD,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.appendTextAndUploadDoc(),
        method: "POST",
        json: {bucket_name, file_name, text_to_add},
      },
      commit: {
        type: aceType.APPEND_AND_UPLOAD_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.APPEND_AND_UPLOAD_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const resetDfc = () => ({
  type: aceType.RESET_DFC,
});

export const createAceRecord = (aceId, plannedCountries, wirelessTechnologies) => ({
  type: aceType.CREATE_ACE_RECORD,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.createAceRecord(),
        method: "POST",
        json: {
          ace_id: aceId,
          planned_countries: plannedCountries,
          wireless_technologies: wirelessTechnologies,
        },
      },
      commit: {
        type: aceType.CREATE_ACE_RECORD_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.CREATE_ACE_RECORD_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const startJiraAutomation = (aceId, productCode, jiraProjectId, jiraSecurityLevel, aceFileName) => ({
  type: aceType.JIRA_AUTOMATION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.jiraAutomation(),
        method: "POST",
        json: {
          ace_id: aceId,
          ace_file_name: aceFileName,
          product_code_name: productCode,
          jira_project_id: jiraProjectId,
          jira_security_level: jiraSecurityLevel
        },
      },
      commit: {
        type: aceType.JIRA_AUTOMATION_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.JIRA_AUTOMATION_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const addLimitsSectionAction = (aceId, cycleDutyValue, eirpPsd, aceFileName) => ({
  type: aceType.ADD_LIMITS_SECTION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.addLimitsSection(),
        method: "POST",
        json: {
          ace_id: aceId,
          ace_file_name: aceFileName,
          cycle_duty_value: cycleDutyValue,
          eirp_psd: eirpPsd
        },
      },
      commit: {
        type: aceType.ADD_LIMITS_SECTION_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.ADD_LIMITS_SECTION_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const addStandardsSection = ({
                                      countryList,
                                      technology_list,
                                      brd_file_name,
                                    }) => ({
  type: aceType.ADD_STANDARDS_SECTION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.addStandardsSection(),
        method: "POST",
        json: {countryList, technology_list, brd_file_name},
      },
      commit: {
        type: aceType.ADD_STANDARDS_SECTION_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.ADD_STANDARDS_SECTION_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const downloadAceDocument = (aceFileName) => ({
  type: aceType.DOWNLOAD_ACE_DOCUMENT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.downloadAceDocument(),
        method: "POST",
        json: {
          ace_file_name: aceFileName,
        },
      },
      commit: {
        type: aceType.DOWNLOAD_ACE_DOCUMENT_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.DOWNLOAD_ACE_DOCUMENT_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

