export const CATEGORY_TEST_PLAN_MAPPING = {
  Tablets: {
    1: "Color bar, 2.4 GHz Wi-Fi link, BT Link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers, Camera stress, Vertical orientation, Microphone active, LED(s) on, powered via AC Adapter",
    2: "Color bar, 2.4 GHz Wi-Fi link, BT Link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers, Camera stress, Horizontal orientation, Microphone active, LED(s) on, powered via AC Adapter",
    3: "Color bar, 5 GHZ Wi-Fi link, Zigbee link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via headphones, data transfer, Camera stress, Horizontal orientation, Microphone active, LED(s) on, powered via AC Adapter",
    4: "Color bar, 6 GHz Wi-Fi link, Zigbee link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via Bluetooth, data transfer, Camera stress, Worst orientation, Microphone active, LED(s) on, powered via AC Adapter",
    5: "Worst case from 1-4, Battery mode",
  },
  EReader: {
    1: "Color bar, 2.4 GHz Wi-Fi link, BT Link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers, Camera stress, Vertical orientation, Microphone active, LED(s) on, Stylus, powered via AC Adapter",
    2: "Color bar, 2.4 GHz Wi-Fi link, BT Link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers, Camera stress, Horizontal orientation, Microphone active, LED(s) on, Stylus, powered via AC Adapter",
    3: "Color bar, 5 GHz Wi-Fi link, BT link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via headphones, data transfer, Camera stress, Worst case orientation, Microphone active, LED(s) on, Stylus, powered via AC Adapter",
    4: "Color bar, 6 GHz Wi-Fi link, BT link, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via Bluetooth, data transfer, Camera stress, Worst case orientation, Microphone active, LED(s) on, Stylus, powered via AC Adapter",
    5: "Worst case from 1-4, Battery mode",
  },
  AUCC: {
    1: "Color bar, 2.4 GHz Wi-Fi link, BT Link, Zigbee link, Lora 1X, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers, Bluetooth connected to remote, data transfer, Camera stress, Vertical orientation, Microphone active, LED(s) on, powered via AC Adapter",
    2: "Color bar, 2.4 GHz Wi-Fi link, Zigbee link, LoRa Tx, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers, Bluetooth connected to remote, data transfer, Camera stress, Horizontal orientation, Microphone active, LED(s) on, powered via AC",
    3: "Color bar, 5 GHz Wi-Fi link, Zigbee link, LoRa Tx, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via headphones, Bluetooth connected to remote, data transfer, Camera stress, Worst orientation, Microphone active, LED(s) on, powered via AC Adapter",
    4: "Color bar, 6 GHz Wi-Fi link, Zigbee link, LoRa Tx, CPU Stress, DDR Stress, EMMC Stress, Max brightness, Audio via speakers/headphones (worst case), Bluetooth connected to remote, data transfer, Camera stress, Worst orientation, Microphone active, LED(s) on, powered via AC Adapter",
    5: "Worst case from 1-4, using PoE, PoE RJ45 terminated",
    6: "Worst case from 1-4, using Rugen",
  },
  SMPRemote: {
    1: "Bluetooth Idle, All sensors on, LED's on, Microphone active",
    2: "Bluetooth Link, All sensors on, LED's on, Microphone active",
  },
  SMPFireTVSticks: {
    1: "Connected to TV but idle, wireless idle, with extender",
    2: "720p 8 bit, 2.4 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.027 GHz, TMDS clock rate 0.27 MHz, with extender",
    3: "1080p 60 Hz 8 bit, 5 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.148 GHz, TMDS clock rate 148.5 MHz, with extender",
    4: "1080p 60 Hz 10 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.18563 GHz, TMDS clock rate 185.63 MHz, with extender",
    5: "1080p 60 Hz 12 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.222 GHz, TMDS clock rate 222.75 MHz, with extender",
    6: "1080p 24 Hz 8 bit, 10 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.074 GHz, TMDS clock rate 74.25 MHz, with extender",
    7: "1080p 24 Hz 10 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.092 GHz, TMDS clock rate 92.81 MHz, with extender",
    8: "1080p 24 Hz 12 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.111 GHz, TMDS clock rate 111.38 MHz, with extender",
    9: "4K 24 Hz 8 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 2.97 GHz, TMDS clock rate 297 MHz, with extender",
    10: "4K 60 Hz 8 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 5.94 GHz, TMDS clock rate 148.5 MHz, with extender",
    11: "4K 60 Hz 10 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 3.713 GHz, TMDS clock rate 92.813 MHz, with extender",
    12: "4K 24 Hz DoVi 12b, 6 GHZ Wi-Fi, Remote connected via BT, TMDS data rate 4.45 GHz, TMDS clock rate 111.375 MHz, with extender",
    13: "4K 60 Hz DoVi 12b, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 4.45 GHz, TMDS clock rate 111.375 MHz, with extender",
    14: "Connected to TV but idle, wireless idle, without extender",
    15: "720p 8 bit, 2.4 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.027 GHz, TMDS clock rate 0.27 MHz, without extender",
    16: "1080p 60 Hz 8 bit, 5 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.148 GHz, TMDS clock rate 148.5 MHz, without extender",
    17: "1080p 60 Hz 10 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.18563 GHz, TMDS clock rate 185.63 MHz, without extender",
    18: "1080p 60 Hz 12 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.222 GHz, TMDS clock rate 222.75 MHz, without extender",
    19: "1080p 24 Hz 8 bit, 10 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.074 GHz, TMDS clock rate 74.25 MHz, without extender",
    20: "1080p 24 Hz 10 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.092 GHz, TMDS clock rate 92.81 MHz, without extender",
    21: "1080p 24 Hz 12 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 0.111 GHz, TMDS clock rate 111.38 MHz, without extender",
    22: "4K 24 Hz 8 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 2.97 GHz, TMDS clock rate 297 MHz, without extender",
    23: "4K 60 Hz 8 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 5.94 GHz, TMDS clock rate 148.5 MHz, without extender",
    24: "4K 60 Hz 10 bit, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 3.713 GHz, TMDS clock rate 92.813 MHz, without extender",
    25: "4K 24 Hz DoVi 12b, 6 GHZ Wi-Fi, Remote connected via BT, TMDS data rate 4.45 GHz, TMDS clock rate 111.375 MHz, without extender",
    26: "4K 60 Hz DoVi 12b, 6 GHz Wi-Fi, Remote connected via BT, TMDS data rate 4.45 GHz, TMDS clock rate 111.375 MHz, without extender",
    28: "720p worst case with or without extender using Rugen adapter, RJ45 connected and pinging",
    29: "Worst case from modes 3-8 with Rugen adapter, RJ45 connected and pinging",
    30: "Worst case from modes 9-13 with Rugen adapter, RJ45 connected and pinging",
    31: "Worst case mode with ARC/eARC",
    32: "Worst case from modes 1-27 with PiP",
  },
};

export const SECTION_LIST = [
  "FCC Radiated EMI",
  "FCC Conducted EMI",
  "CE Radiated EMI",
  "CE Conducted EMI",
];

export const DEFAULT_BUILD_CONFIG = "DFC-config";

export enum BuildConfigLabel {
  Regular = "Regular",
  Spotcheck = "Spotcheck",
  Default = "DFC",
}

export enum AceDocRowHeaders {
  ExecutiveSummary = "Executive Summary",
  MLP = "MLP",
  Schedule = "Schedule",
  CountryList = "Country List",
  SystemBlockDiagram = "System Block Diagram",
  SystemPowerTree = "System Power Tree",
  WirelessArchitecture = "Wireless Architecture",
}

export enum AceTechnology {
  Wifi24 = "WiFi 2.4 GHz",
  Bt = "BT",
  Ble = "BLE",
  Zigbee = "Zigbee",
  Lora = "Lora",
  Wifi5 = "Wifi 5GHz",
  Wifi6 = "Wifi 6E",
  WifiRadar = "Wi-Fi Radar",
}

export const BRD_TECHNOLOGY_LIST = [
  AceTechnology.Wifi24,
  AceTechnology.Bt,
  AceTechnology.Ble,
  AceTechnology.Zigbee,
  AceTechnology.Lora,
  AceTechnology.Wifi5,
  AceTechnology.Wifi6,
  AceTechnology.WifiRadar,
];

export enum CountryCode {
  US = "US",
  CA = "CA",
  EU = "EU",
  UK = "UK",
  Japan = "Japan",
  AU_NZ = "AU/NZ",
  IN = "IN",
  MX = "MX",
  BR = "BR",
  UAE = "UAE",
  KSA = "KSA",
  CE = "CE",
  CH = "CH",
  SK = "SK",
  ARG = "ARG",
  PAN = "PAN",
  COL = "COL",
  CHI = "CHI",
  EG = "EG",
  KU = "KU",
  SA = "SA",
  QAT = "QAT",
  HK = "HK",
  MAL = "MAL",
  SING = "SING",
  INDO = "INDO",
  PHIL = "PHIL",
  PERU = "PERU",
  ECU = "ECU",
}

export const COUNTRY_LIST = [
  CountryCode.US,
  CountryCode.CA,
  CountryCode.EU,
  CountryCode.UK,
  CountryCode.Japan,
  CountryCode.AU_NZ,
  CountryCode.IN,
  CountryCode.MX,
  CountryCode.BR,
  CountryCode.UAE,
  CountryCode.KSA,
  CountryCode.CE,
  CountryCode.CH,
  CountryCode.SK,
  CountryCode.ARG,
  CountryCode.PAN,
  CountryCode.COL,
  CountryCode.CHI,
  CountryCode.EG,
  CountryCode.KU,
  CountryCode.SA,
  CountryCode.QAT,
  CountryCode.HK,
  CountryCode.MAL,
  CountryCode.SING,
  CountryCode.INDO,
  CountryCode.PHIL,
  CountryCode.PERU,
  CountryCode.ECU,
];
