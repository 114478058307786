const idRegexAcceptable = /^[0-9]{1,2}[a-z]{0,1}$/;
const idRegexNotAcceptable = /^0[0-9]+/;
const unsupportedCharacterMessage = "_  /  \\  '  are not supported";

export const validateStringInput = (value) => {
  if (
    value.includes("_") ||
    value.includes("/") ||
    value.includes("\\") ||
    value.includes("'") ||
    value.includes('"')
  ) {
    return true;
  }
  return false;
};

export let sectionErrorsCopy: any[] = [];

export const validate = (values) => {
  const errors: any = {};
  sectionErrorsCopy = [];
  if (!values || !values.sections || values.sections.length === 0) {
    errors.sections = {
      _error:
        "At least one sheet must be added before submitting. Use Add Sheet button to add sheets.",
    };
  } else {
    var sectionsArrayErrors: any = [];
    values.sections.forEach((section, sectionIndex) => {
      var sectionErrors: any = {};
      if (!section.name || section.name === "") {
        sectionErrors.name = { _error: "Section name cannot be empty" };
        sectionsArrayErrors[sectionIndex] = sectionErrors;
      }
      if (!section.testCases || section.testCases.length === 0) {
        sectionErrors.testCases = {
          _error: "Sheet must have at least one test case",
        };
        sectionsArrayErrors[sectionIndex] = sectionErrors;
      } else {
        var testCaseArrayErrors: any = [];
        var perSectionIds = new Set();
        section.testCases.forEach((testCase, testCaseIndex) => {
          var testCaseErrors: any = {};
          if (!testCase.sheet_id) {
            testCaseErrors.sheet_id = "*";
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          } else if (
            !idRegexAcceptable.test(testCase.sheet_id) ||
            idRegexNotAcceptable.test(testCase.sheet_id)
          ) {
            testCaseErrors.sheet_id = "Incorrect format";
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          } else if (perSectionIds.has(testCase.sheet_id)) {
            testCaseErrors.sheet_id = "Duplicate";
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          } else {
            perSectionIds.add(testCase.sheet_id);
          }
          if (!testCase.name) {
            testCaseErrors.name = "*";
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          }
          if (testCase.config && validateStringInput(testCase.config)) {
            testCaseErrors.config = unsupportedCharacterMessage;
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          }
          if (!testCase.section) {
            testCaseErrors.section = "*";
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          } else if (validateStringInput(testCase.section)) {
            testCaseErrors.section = unsupportedCharacterMessage;
            testCaseArrayErrors[testCaseIndex] = testCaseErrors;
          }
        });
        sectionErrors.testCases = testCaseArrayErrors;
        sectionsArrayErrors[sectionIndex] = sectionErrors;
      }
      sectionErrorsCopy = sectionsArrayErrors;
    });

    errors.sections = sectionsArrayErrors;
  }
  return errors;
};
