import { SelectProps } from "@amzn/awsui-components-react-v3";
import { METRIC_INFO } from "../constants";
import {
  BuildConfigLabel,
  CATEGORY_TEST_PLAN_MAPPING,
  DEFAULT_BUILD_CONFIG,
  SECTION_LIST,
} from "./constants";

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getLogData = (
  feature: string,
  initiative: string = METRIC_INFO.INITIATIVE_RF,
  test_category: any = null,
  category: any = null,
  project: any = null
) => {
  const data: {} = { initiative: initiative, feature };
  if (typeof test_category === "string" && test_category.length > 0)
    data["testcategory"] = test_category; // need to remove _, otherwise can't match in Kibana
  if (typeof category === "string" && category.length > 0)
    data["category"] = capitalizeFirstLetter(category);
  if (typeof project === "string" && project.length > 0)
    data["project"] = capitalizeFirstLetter(project);

  return data;
};

export const getBuildAndVersion = (project: any, build_id: any) => {
  let build = "";
  let version = "";
  Object.keys(project.children).forEach((build_item) => {
    Object.keys(project.children[build_item].children).forEach(
      (version_item) => {
        if(typeof(project.children[build_item].children[version_item].id) === 'number' &&
          project.children[build_item].children[version_item].id ===
            Number(build_id)){
          build = build_item;
          version = version_item;
        }
        else if(
          project.children[build_item].children[version_item].id ===
          build_id
        ) {
          build = build_item;
          version = version_item;
        }
      }
    );
  });
  return `${build}_${version}`;
};

export const getProjectItems = (filter: any, categoryId: string) => {
  let projectItems: Array<SelectProps.Option> = [];
  Object.keys(filter[categoryId]?.children || {}).forEach((projectId) => {
    const project = filter[categoryId].children[projectId];
    const item: SelectProps.Option = { value: projectId, label: project?.name };
    projectItems.push(item);
  });
  return projectItems;
};

export const getBuildItems = (
  filter: any,
  categoryId: string,
  projectId: string
) => {
  let buildItems: Array<SelectProps.Option> = [];
  Object.keys(
    filter[categoryId]?.children?.[projectId]?.children || {}
  ).forEach((build) => {
    const item: SelectProps.Option = { value: build, label: build };
    buildItems.push(item);
  });
  return buildItems;
};

export const getVersionItems = (
  filter: any,
  categoryId: string,
  projectId: string,
  build: string
) => {
  let versionItems: Array<SelectProps.Option> = [];
  Object.keys(
    filter[categoryId]?.children?.[projectId]?.children?.[build]?.children || {}
  ).forEach((version) => {
    const item: SelectProps.Option = { value: version, label: version };
    versionItems.push(item);
  });
  return versionItems;
};

export const getBuildConfigItems = (
  filter: any,
  categoryId: string,
  projectId: string,
  build: string,
  version: any
) => {
  let buildConfigItems: Array<SelectProps.Option> = [];
  let regularConfigItems: Array<SelectProps.Option> = [];
  let spotCheckConfigItems: Array<SelectProps.Option> = [];
  let defaultConfigItems: Array<SelectProps.Option> = [];

  const buildConfigDict =
    filter[categoryId]?.children?.[projectId]?.children?.[build]?.children?.[
      version
    ]?.children;

  Object.keys(buildConfigDict || {}).forEach((buildConfigId) => {
    const configDictItem = buildConfigDict[buildConfigId];

    const item: SelectProps.Option = {
      value: buildConfigId,
      label: configDictItem?.name,
    };
    const isSpotCheck = configDictItem?.is_spot_check;
    const isDefaultConfig = configDictItem?.name == DEFAULT_BUILD_CONFIG;

    if (isSpotCheck) spotCheckConfigItems.push(item);
    else if (isDefaultConfig) defaultConfigItems.push(item);
    else regularConfigItems.push(item);
  });
  if (defaultConfigItems.length) {
    const item: SelectProps.OptionGroup = {
      label: BuildConfigLabel.Default,
      options: defaultConfigItems,
    };
    buildConfigItems.push(item);
  }
  if (regularConfigItems.length) {
    const item: SelectProps.OptionGroup = {
      label: BuildConfigLabel.Regular,
      options: regularConfigItems,
    };
    buildConfigItems.push(item);
  }
  if (spotCheckConfigItems.length) {
    const item: SelectProps.OptionGroup = {
      label: BuildConfigLabel.Spotcheck,
      options: spotCheckConfigItems,
    };
    buildConfigItems.push(item);
  }
  return buildConfigItems;
};

export const getInitialFields = (categoryName: string, versionId: string) => {
  if (!CATEGORY_TEST_PLAN_MAPPING[categoryName]) return [];

  const testCases = Object.entries(
    CATEGORY_TEST_PLAN_MAPPING[categoryName]
  ).map(([sheet_id, name]) => ({
    sheet_id,
    name,
    section: "",
    sheet_name: "",
    config: "dummy-config",
    build_id: versionId,
  }));

  return SECTION_LIST.map((section) => ({
    name: section,
    testCases: testCases.map((testCase) => ({
      ...testCase,
      section,
      sheet_name: section,
    })),
  }));
};
