import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import QuestionnaireTable from "./table/table";
import QuestionnaireHeader from "../../../components/questionnaire-header/header";
import {
  MultiselectRegion,
  SelectLab,
  AssigneeField,
  SelectTechnology,
  MultiselectSpecification,
  AntennaField,
  InternalMarginField,
  TargetPowerField,
  MinPowerField,
  AntennaGainField,
  TargetPowerFieldWithSubPower,
  MinPowerFieldWithSubPower,
} from "../../../components/questionnaire-form-fields/form-fields";
import { tableData } from "./table/sample-data";
import constants, { DROPDOWN_DEFAULT } from "../../../constants";

import {
  SelectProps,
  Input,
  Button,
  FormField,
  MultiselectProps,
  SpaceBetween,
  StatusIndicator,
  Modal,
  Box,
  Toggle,
} from "@amzn/awsui-components-react-v3";
import {
  resetSendMail,
  sendMailTestplan,
} from "../../../redux/actions/testplan-action";
import Container from "@amzn/awsui-components-react-v3/polaris/container/internal";
import {
  createTestplanRequest,
  DownloadPreSignedURLRequest,
} from "../../../model/http-json";
import { parseS3Link } from "../../../utils/s3-utils";
import { downloadAction } from "../../../redux/actions/download-action";

interface StateProps {
  testplanReducer: any;
  downloadReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  onSubmit: any;
  categoryId: string;
  projectId: string;
  buildId: string;
  buildConfigId: number;
} & StateProps;

type State = {
  countrySelectedOptions: any;
  countryError: string;
  tech_id: string;
  tech: string;
  techError: string;
  selected_specs: Array<any>;
  specError: string;
  specItems: Array<any>;
  antenna: string;
  antennaItems: Array<any>;
  editAntenna: string;
  editAntennaError: string;
  antennaModal: boolean;
  antennaError: string;
  tableData: Array<any>;
  tableError: string;
  duplicateData: boolean;
  editModal: boolean;
  editRow: Array<any>;
  editRowError: Array<any>;
  editRowCopy: Array<any>;
  lab: string;
  labDisable: boolean;
  labError: string;
  assignee: string;
  assigneeError: string;
  internalMargin: string;
  editInternalMargin: string;
  editInternalMarginError: string;
  internalMarginModal: boolean;
  internalMarginError: string;
  target: string;
  subTarget: string;
  targetError: string;
  minPower: string;
  subMinPower: string;
  minPowerError: string;
  antennaGain: string;
  antennaGainError: string;
  selectedItems: Array<any>;
  subPowerSettingMode: boolean;
  s3Url: string;
};

const antennaDropDown = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
];

const defaultInternalMargin = "3";

class Questionnaire extends Component<Props, State> {
  state: State = Object.freeze({
    countrySelectedOptions: [],
    countryError: "",
    tech_id: "",
    tech: DROPDOWN_DEFAULT.TECHNOLOGY,
    techError: "",
    selected_specs: [],
    specError: "",
    specItems: [],
    antenna: DROPDOWN_DEFAULT.ANTENNA,
    editAntenna: "",
    antennaModal: false,
    antennaItems: antennaDropDown,
    editAntennaError: "",
    antennaError: "",
    tableData: [],
    tableError: "",
    duplicateData: false,
    editModal: false,
    editRow: [],
    editRowError: [],
    editRowCopy: [],
    lab: DROPDOWN_DEFAULT.LAB,
    labDisable: false,
    labError: "",
    assignee: "",
    assigneeError: "",
    internalMargin: defaultInternalMargin,
    editInternalMargin: defaultInternalMargin,
    editInternalMarginError: "",
    internalMarginModal: false,
    internalMarginError: "",
    target: "",
    subTarget: "",
    targetError: "",
    minPower: "",
    subMinPower: "",
    minPowerError: "",
    antennaGain: "",
    antennaGainError: "",
    selectedItems: [],
    subPowerSettingMode: false,
    s3Url: "",
  });

  componentDidMount() {
    this.prefillQuestionnaire();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevLoadingLatestTestplanStatus =
      prevProps.testplanReducer.loadingLatestTestplanStatus;
    const { loadingLatestTestplanStatus } = this.props.testplanReducer;
    const prevLoadingTestplanDraftStatus =
      prevProps.testplanReducer.loadingTestplanDraftStatus;
    const { loadingTestplanDraftStatus } = this.props.testplanReducer;
    const prevLoadingImportTestplanStatus =
      prevProps.testplanReducer.loadingImportTestplanStatus;
    const { loadingImportTestplanStatus } = this.props.testplanReducer;
    const { loadingStatus, url } = this.props.downloadReducer;
    if (
      (prevLoadingLatestTestplanStatus !== loadingLatestTestplanStatus &&
        loadingLatestTestplanStatus === constants.LOADING_SUCCESS) ||
      (prevLoadingTestplanDraftStatus !== loadingTestplanDraftStatus &&
        loadingTestplanDraftStatus === constants.LOADING_SUCCESS) ||
      (prevLoadingImportTestplanStatus !== loadingImportTestplanStatus &&
        loadingImportTestplanStatus === constants.LOADING_SUCCESS)
    ) {
      this.prefillQuestionnaire();
    }
    if (
      loadingLatestTestplanStatus === constants.LOADING_SUCCESS &&
      this.props.testplanReducer.latestTestplanData.s3_link &&
      this.props.testplanReducer.latestTestplanData.s3_link !==
        prevProps.testplanReducer.latestTestplanData.s3_link
    ) {
      this.generate_presigned_url(
        this.props.testplanReducer.latestTestplanData.s3_link
      );
    }

    if (loadingStatus === constants.LOADING_SUCCESS) {
      if (this.state.s3Url === "" || this.state.s3Url !== url) {
        this.setState({ s3Url: url });
      }
    }
  }

  prefillQuestionnaire = () => {
    const { latestTestplanData, loadingLatestTestplanStatus } =
      this.props.testplanReducer;
    const { loadingStatus, url } = this.props.downloadReducer;
    if (latestTestplanData) {
      if (
        loadingLatestTestplanStatus === constants.LOADING_SUCCESS &&
        this.props.testplanReducer.latestTestplanData.s3_link
      ) {
        this.generate_presigned_url(
          this.props.testplanReducer.latestTestplanData.s3_link
        );
      }
      if (loadingStatus === constants.LOADING_SUCCESS) {
        if (this.state.s3Url === "" || this.state.s3Url !== url) {
          this.setState({ s3Url: url });
        }
      }
      const { countries, techSpec } = this.props.testplanReducer;
      let countrySelectedOptions: Array<any> = [];
      if (latestTestplanData.countries) {
        latestTestplanData.countries.forEach((country_id: number) => {
          const item: MultiselectProps.Option = {
            value: String(country_id),
            label: countries[country_id].name,
          };
          countrySelectedOptions.push(item);
        });
      }
      let tableData: Array<any> = [];
      if (latestTestplanData.tech_specs) {
        latestTestplanData.tech_specs.forEach((tech_spec: any) => {
          const tableDataRow: any = {
            technology: techSpec[tech_spec.technology_id].name,
            technology_id: tech_spec.technology_id,
            specification:
              techSpec[tech_spec.technology_id].children[tech_spec.tech_spec_id]
                .name,
            specification_id: tech_spec.tech_spec_id,
            antenna: tech_spec.antenna,
            internal_margin: tech_spec.internal_margin,
            minimum_power: tech_spec.minimum_power,
            target: tech_spec.target,
            antenna_gain: tech_spec.antenna_gain,
          };
          tableData.push(tableDataRow);
        });
      }
      let assignee = this.state.assignee;
      if (!assignee)
        assignee = latestTestplanData.assignee
          ? latestTestplanData.assignee
          : "";
      let lab = this.state.lab;
      if (lab === DROPDOWN_DEFAULT.LAB)
        lab = latestTestplanData.lab
          ? latestTestplanData.lab
          : DROPDOWN_DEFAULT.LAB;
      const labDisable = latestTestplanData.lab ? true : false;
      this.setState({
        countrySelectedOptions,
        tableData,
        assignee,
        lab,
        labDisable,
      });
    }
  };

  generate_presigned_url = (s3_link) => {
    const s3_components = parseS3Link(s3_link);
    const data: DownloadPreSignedURLRequest = {
      file_name: s3_components.file_path,
      object_key: s3_components.object_key,
      bucket_name: s3_components.bucket_name,
      version_id: s3_components.version_id,
    };
    this.props.dispatch(downloadAction(data));
  };
  validateTechSpec = () => {
    let isValid = true;
    let techError = "";
    let specError = "";
    let antennaError = "";
    let internalMarginError = "";
    let targetError = "";
    let minPowerError = "";
    let antennaGainError = "";

    if (!this.state.tech_id || !Number(this.state.tech_id)) {
      isValid = false;
      techError = "Required field";
    }
    if (!this.state.selected_specs || this.state.selected_specs.length === 0) {
      isValid = false;
      specError = "Required field";
    }
    if (
      !this.state.antenna ||
      this.state.antenna === DROPDOWN_DEFAULT.ANTENNA
    ) {
      isValid = false;
      antennaError = "Required field";
    } else if (!/^[0-9A-Za-z+,.()/_\-]+$/i.test(this.state.antenna)) {
      isValid = false;
      antennaError =
        "Only alphabets, numbers and special characters (,.()/_-) allowed";
    }
    if (!this.state.internalMargin) {
      isValid = false;
      internalMarginError = "Required field";
    } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(this.state.internalMargin)) {
      isValid = false;
      internalMarginError = "Numeric values only";
    }
    if (this.state.target) {
      if (
        !this.state.subPowerSettingMode &&
        !/^[0-9]+(\.[0-9]+)?$/i.test(this.state.target)
      ) {
        isValid = false;
        targetError = "Numeric values only";
      }
      if (
        this.state.subPowerSettingMode &&
        (!/^[0-9]+$/i.test(this.state.target) ||
          !/^[0-9]+$/i.test(this.state.subTarget))
      ) {
        isValid = false;
        targetError = "Integer values only";
      }
    }
    if (!this.state.minPower) {
      isValid = false;
      minPowerError = "Required field";
    } else {
      if (
        !this.state.subPowerSettingMode &&
        !/^[0-9]+(\.[0-9]+)?$/i.test(this.state.minPower)
      ) {
        isValid = false;
        minPowerError = "Numeric values only";
      }
      if (
        this.state.subPowerSettingMode &&
        (!/^[0-9]+$/i.test(this.state.minPower) ||
          !/^[0-9]+$/i.test(this.state.subMinPower))
      ) {
        isValid = false;
        minPowerError = "Integer values only";
      }
    }
    if (
      this.state.antennaGain &&
      !/^[0-9]+(\.[0-9]+)?$/i.test(this.state.antennaGain)
    ) {
      isValid = false;
      antennaGainError = "Numeric values only";
    }
    this.setState({
      techError,
      specError,
      antennaError,
      internalMarginError,
      targetError,
      minPowerError,
      antennaGainError,
    });
    return isValid;
  };

  validateFormData = () => {
    let isValid = true;
    let countryError = "";
    let tableError = "";
    let labError = "";
    let assigneeError = "";

    if (this.state.countrySelectedOptions.length === 0) {
      countryError = "Required Field";
      isValid = false;
    }
    if (this.state.tableData.length === 0) {
      tableError = "Table Data required";
      isValid = false;
    }
    if (!this.state.lab || this.state.lab === DROPDOWN_DEFAULT.LAB) {
      labError = "Required Field";
      isValid = false;
    }
    if (!this.state.assignee) {
      assigneeError = "Required Field";
      isValid = false;
    } else if (!/^[A-Za-z]+$/i.test(this.state.assignee)) {
      assigneeError = "Letters only";
      isValid = false;
    }
    this.setState({
      countryError,
      tableError,
      labError,
      assigneeError,
    });
    return isValid;
  };

  getCountryOptions = (countries: any): any => {
    const items: Array<MultiselectProps.Option> = [];
    Object.keys(countries).forEach((country_id) => {
      const item: MultiselectProps.Option = {
        value: country_id,
        label: countries[country_id].name,
      };
      items.push(item);
    });
    return items;
  };

  getLabOptions = (): any => {
    const items: Array<SelectProps.Option> = [];
    items.push({ value: "TUV", label: "TUV" });
    items.push({ value: "SPORTON", label: "SPORTON" });
    return items;
  };

  _onGetMail = () => {
    const { categoryId, projectId, buildId, buildConfigId } = this.props;
    this.props.dispatch(
      sendMailTestplan(categoryId, projectId, buildId, buildConfigId)
    );
  };

  handleCountryChange = (event: any): any => {
    this.setState({
      countrySelectedOptions: event.detail.selectedOptions,
      countryError: "",
    });
  };

  handleLabChange = (event: any): any => {
    const lab = event.detail.selectedOption.value;
    this.setState({
      lab,
      labError: "",
    });
  };

  getTechOptions = (techSpec: any): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(techSpec).forEach((tech_id) => {
      const item: SelectProps.Option = {
        value: tech_id,
        label: techSpec[tech_id].name,
      };
      items.push(item);
    });
    return items;
  };

  _onChangeTechnology = (event: any): any => {
    const tech_id = event.detail.selectedOption.value;
    const tech = event.detail.selectedOption.label;
    const { techSpec } = this.props.testplanReducer;
    let spec_items: Array<SelectProps.Option> = [];
    Object.keys(techSpec[tech_id].children).forEach((spec_id) => {
      const item: SelectProps.Option = {
        value: spec_id,
        label: techSpec[tech_id].children[spec_id].name,
      };
      spec_items.push(item);
    });
    this.setState({
      tech_id,
      tech,
      techError: "",
      selected_specs: [],
      specError: "",
      specItems: spec_items,
      duplicateData: false,
    });
  };

  _onChangeSpecification = (event: any): any => {
    this.setState({
      selected_specs: event.detail.selectedOptions,
      specError: "",
      duplicateData: false,
    });
  };

  _onChangeAntenna = (event) => {
    const antenna: string = event.detail.selectedOption.value;
    this.setState({
      antenna,
      antennaError: "",
      duplicateData: false,
    });
  };
  _showAntennaModal = () => {
    this.setState({ antennaModal: true });
  };
  _onChangeAntennaInput = (event) => {
    const editAntenna: string = event.detail.value;
    this.setState({
      editAntenna,
      editAntennaError: "",
    });
  };
  _handleAddAntennaSubmit = () => {
    let editAntennaError = "";
    let isValid = true;
    if (!this.state.editAntenna) {
      isValid = false;
      editAntennaError = "Required field";
    } else if (!/^[0-9A-Za-z+,.()/_\-]+$/i.test(this.state.editAntenna)) {
      isValid = false;
      editAntennaError =
        "Only alphabets, numbers and special characters (,.()/_-) allowed";
    }

    if (isValid) {
      let antennaItems = [
        ...this.state.antennaItems,
        { label: this.state.editAntenna, value: this.state.editAntenna },
      ];
      this.setState({
        antennaItems,
        editAntennaError,
        antennaModal: false,
      });
    } else {
      this.setState({
        editAntennaError,
      });
    }
  };

  _onChangeInternalMargin = (event) => {
    const editInternalMargin: string = event.detail.value;
    this.setState({
      editInternalMargin,
      editInternalMarginError: "",
      duplicateData: false,
    });
  };

  _showInternalMarginModal = () => {
    this.setState({ internalMarginModal: true });
  };

  _handleInternalMarginSubmit = () => {
    let editInternalMarginError = "";
    let isValid = true;
    if (!this.state.editInternalMargin) {
      isValid = false;
      editInternalMarginError = "Required field";
    } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(this.state.editInternalMargin)) {
      isValid = false;
      editInternalMarginError = "Numeric values only";
    }
    if (isValid) {
      this.setState({
        internalMargin: this.state.editInternalMargin,
        editInternalMarginError,
        internalMarginModal: false,
      });
    } else {
      this.setState({
        editInternalMarginError,
      });
    }
  };

  _onChangePowerSettingMode = (event) => {
    const checked: boolean = event.detail.checked;
    this.setState({
      subPowerSettingMode: checked,
      target: "",
      subTarget: "",
      minPower: "",
      subMinPower: "",
      targetError: "",
      minPowerError: "",
      duplicateData: false,
    });
  };

  _onChangeTarget = (event) => {
    const target: string = event.detail.value;
    this.setState({
      target,
      targetError: "",
      duplicateData: false,
    });
  };

  _onChangeSubTarget = (event) => {
    const subTarget: string = event.detail.value;
    this.setState({
      subTarget,
      targetError: "",
      duplicateData: false,
    });
  };

  _onChangeMinPower = (event) => {
    const minPower: string = event.detail.value;
    this.setState({
      minPower,
      minPowerError: "",
      duplicateData: false,
    });
  };

  _onChangeSubMinPower = (event) => {
    const subMinPower: string = event.detail.value;
    this.setState({
      subMinPower,
      minPowerError: "",
      duplicateData: false,
    });
  };

  _onChangeAntennaGain = (event) => {
    const antennaGain: string = event.detail.value;
    this.setState({
      antennaGain,
      antennaGainError: "",
      duplicateData: false,
    });
  };

  _onChangeAssignee = (event: any): any => {
    const assignee: string = event.detail.value;
    this.setState({
      assignee,
      assigneeError: "",
    });
  };

  _onClear = (): any => {
    this.setState({
      tech_id: "",
      tech: DROPDOWN_DEFAULT.TECHNOLOGY,
      techError: "",
      selected_specs: [],
      specError: "",
      specItems: [],
      antenna: DROPDOWN_DEFAULT.ANTENNA,
      antennaError: "",
      editAntenna: "",
      editAntennaError: "",
      antennaModal: false,
      internalMargin: defaultInternalMargin,
      internalMarginError: "",
      editInternalMargin: defaultInternalMargin,
      editInternalMarginError: "",
      internalMarginModal: false,
      target: "",
      subTarget: "",
      targetError: "",
      minPower: "",
      subMinPower: "",
      minPowerError: "",
      antennaGain: "",
      antennaGainError: "",
      duplicateData: false,
      selectedItems: [],
    });
  };

  _onAdd = (): any => {
    const isValid = this.validateTechSpec();
    if (!isValid) {
      return;
    }
    let tableDataRowArray: Array<any> = [];
    let tableData: Array<any> = [...this.state.tableData];

    for (let spec of this.state.selected_specs) {
      let tableDataRow: any = {
        technology: this.state.tech,
        technology_id: Number(this.state.tech_id),
        specification: spec.label,
        specification_id: Number(spec.value),
        antenna: this.state.antenna,
        internal_margin: this.state.internalMargin,
        target:
          this.state.subPowerSettingMode && this.state.target
            ? `${this.state.target}/${this.state.subTarget}`
            : this.state.target,
        minimum_power: this.state.subPowerSettingMode
          ? `${this.state.minPower}/${this.state.subMinPower}`
          : this.state.minPower,
        antenna_gain: this.state.antennaGain,
      };
      let index = -1;
      tableData.forEach((item, idx) => {
        let tableDataItem: any = {
          technology: item.technology,
          technology_id: Number(item.technology_id),
          specification: item.specification,
          specification_id: Number(item.specification_id),
          antenna: item.antenna,
        };
        let tableDataRowItem: any = {
          technology: tableDataRow.technology,
          technology_id: Number(tableDataRow.technology_id),
          specification: tableDataRow.specification,
          specification_id: Number(tableDataRow.specification_id),
          antenna: tableDataRow.antenna,
        };
        if (
          JSON.stringify(tableDataItem) === JSON.stringify(tableDataRowItem)
        ) {
          index = idx;
        }
      });
      if (index > -1) {
        this.setState({
          duplicateData: true,
        });
        return;
      }
      tableDataRowArray.push(tableDataRow);
    }

    tableData.push(...tableDataRowArray);
    this.setState({
      tableData,
      tableError: "",
    });
  };

  clear = (): any => {
    const lab = this.state.labDisable ? this.state.lab : DROPDOWN_DEFAULT.LAB;
    this.setState({
      countrySelectedOptions: [],
      countryError: "",
      tech_id: "",
      tech: DROPDOWN_DEFAULT.TECHNOLOGY,
      techError: "",
      selected_specs: [],
      specError: "",
      specItems: [],
      antenna: DROPDOWN_DEFAULT.ANTENNA,
      antennaError: "",
      editAntenna: "",
      editAntennaError: "",
      antennaModal: false,
      internalMargin: defaultInternalMargin,
      internalMarginError: "",
      editInternalMargin: defaultInternalMargin,
      editInternalMarginError: "",
      internalMarginModal: false,
      target: "",
      subTarget: "",
      targetError: "",
      minPower: "",
      subMinPower: "",
      minPowerError: "",
      antennaGain: "",
      antennaGainError: "",
      tableData: [],
      tableError: "",
      duplicateData: false,
      lab,
      labError: "",
      assignee: "",
      assigneeError: "",
      selectedItems: [],
    });
  };

  _onSubmit = (isDraft = false): any => {
    const isValid = this.validateFormData();
    if (!isValid) {
      return;
    }
    const { countrySelectedOptions, tableData } = this.state;
    let countries: Array<number> = [];
    let tech_specs: Array<any> = [];
    countrySelectedOptions.forEach((country: any) => {
      countries.push(Number(country.value));
    });
    tableData.forEach((item: any) => {
      const tech_spec = {
        technology_id: item.technology_id,
        tech_spec_id: item.specification_id,
        antenna: item.antenna,
        internal_margin: Number(item.internal_margin),
        target: item.target,
        minimum_power: item.minimum_power,
        // antenna_gain: Number(item.antenna_gain),
      };
      if (item.antenna_gain) {
        tech_spec["antenna_gain"] = Number(item.antenna_gain);
      }
      tech_specs.push(tech_spec);
    });
    const values: createTestplanRequest = {
      countries,
      tech_specs,
      lab: this.state.lab,
      assignee: this.state.assignee,
    };
    this.props.onSubmit(values, isDraft);
  };

  deleteRow = (selectedItems: Array<any>): any => {
    let tableData = [...this.state.tableData];
    selectedItems.forEach((item) => {
      const index = tableData.indexOf(item);
      if (index > -1) {
        tableData.splice(index, 1);
      }
    });
    this.setState({
      selectedItems: [],
      tableData,
    });
  };

  /* disabling edit row feature
  editRow = (selectedItems: Array<any>): any => {
    let x = JSON.parse(JSON.stringify(selectedItems));
    this.setState({
      editRow: JSON.parse(JSON.stringify(selectedItems)),
      editRowCopy: JSON.parse(JSON.stringify(selectedItems)),
      editModal: true,
    });
  };

  closeEditModal = (): any => {
    this.setState({
      editRow: [],
      editModal: false,
    });
  };

  submitEditModal = (): any => {
    //deep clone table data object
    let tableData = JSON.parse(JSON.stringify(this.state.tableData));
    let editRow = this.state.editRow;
    let editRowError: Array<any> = [];
    let editRowCopy = this.state.editRowCopy;
    let isValid = true;
    editRow.forEach((row, idx) => {
      let item = tableData.filter((item) => {
        return JSON.stringify(item) === JSON.stringify(editRowCopy[idx]);
      });
      item[0]["target"] = row.target;
      item[0]["minimum_power"] = row.minimum_power;
      item[0]["antenna_gain"] = row.antenna_gain;
      let errorRowItem = {};
      if (!row.target) {
        isValid = false;
        errorRowItem["target"] = "Required field";
      } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(row.target)) {
        isValid = false;
        errorRowItem["target"] = "Numeric values only";
      }
      if (!row.minimum_power) {
        isValid = false;
        errorRowItem["minimum_power"] = "Required field";
      } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(row.minimum_power)) {
        isValid = false;
        errorRowItem["minimum_power"] = "Numeric values only";
      }
      if (!row.antenna_gain) {
        isValid = false;
        errorRowItem["antenna_gain"] = "Required field";
      } else if (!/^[0-9]+(\.[0-9]+)?$/i.test(row.antenna_gain)) {
        isValid = false;
        errorRowItem["antenna_gain"] = "Numeric values only";
      }
      editRowError = [...editRowError, errorRowItem];
    });
    if (isValid) {
      this.setState({
        tableData,
        editRow: [],
        editRowCopy: [],
        editModal: false,
        editRowError: [],
        selectedItems: [],
      });
    } else {
      this.setState({
        editRowError,
      });
    }
  };
  */

  render() {
    const {
      countries,
      techSpec,
      createStatus,
      createDraftStatus,
      latestTestplanData,
      loadingSendMailTestPlanStatus,
      sendMailTestPlanMessage,
    } = this.props.testplanReducer;
    const jiraId = latestTestplanData ? latestTestplanData.jira_id : "";
    const s3Link = latestTestplanData ? latestTestplanData.s3_link : "";
    const countryOptions = this.getCountryOptions(countries);
    const labOptions = this.getLabOptions();
    const technologyOptions = this.getTechOptions(techSpec);
    const { tableData } = this.state;
    return (
      <Container
        header={
          <QuestionnaireHeader
            s3Link={this.state.s3Url}
            disableS3Url={s3Link}
            getMail={this._onGetMail}
            loadingSendMailTestPlanStatus={loadingSendMailTestPlanStatus}
            disableImportTestplan={false}
          />
        }
      >
        <div className="d-flex justify-content-start align-items-top flex-wrap mt-3">
          <div className="quesstionnaire-filter-each-filter">
            <MultiselectRegion
              errorText={this.state.countryError}
              options={countryOptions}
              selectedOptions={this.state.countrySelectedOptions}
              onChange={this.handleCountryChange}
            />
          </div>
          <div className="quesstionnaire-filter-each-filter">
            <SelectLab
              options={labOptions}
              disabled={this.state.labDisable}
              onChange={this.handleLabChange}
              value={this.state.lab}
              label={this.state.lab}
              errorText={this.state.labError}
            />
          </div>
          <div className="quesstionnaire-filter-each-filter">
            <AssigneeField
              value={this.state.assignee}
              onChange={this._onChangeAssignee}
              errorText={this.state.assigneeError}
            />
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-start flex-wrap">
          <div className="quesstionnaire-filter-each-filter">
            <SelectTechnology
              options={technologyOptions}
              onChange={this._onChangeTechnology}
              value={this.state.tech_id}
              label={this.state.tech}
              errorText={this.state.techError}
            />
          </div>
          <div className="quesstionnaire-filter-each-filter">
            <MultiselectSpecification
              options={this.state.specItems}
              selectedOptions={this.state.selected_specs}
              onChange={this._onChangeSpecification}
              errorText={this.state.specError}
            />
          </div>
          <div className="quesstionnaire-filter-each-filter">
            <AntennaField
              options={this.state.antennaItems}
              onChange={this._onChangeAntenna}
              value={this.state.antenna}
              label={this.state.antenna}
              errorText={this.state.antennaError}
              showAntennaModel={this._showAntennaModal}
            />
          </div>
          <div className="quesstionnaire-filter-each-filter">
            <InternalMarginField
              value={this.state.internalMargin}
              showInternalMarginModel={this._showInternalMarginModal}
              errorText={this.state.internalMarginError}
            />
          </div>
          <div className="quesstionnaire-filter-each-filter">
            {this.state.subPowerSettingMode === true ? (
              <TargetPowerFieldWithSubPower
                value={this.state.target}
                subValue={this.state.subTarget}
                onChange={this._onChangeTarget}
                subOnChange={this._onChangeSubTarget}
                errorText={this.state.targetError}
              />
            ) : (
              <TargetPowerField
                value={this.state.target}
                onChange={this._onChangeTarget}
                errorText={this.state.targetError}
              />
            )}
            <Toggle
              onChange={this._onChangePowerSettingMode}
              checked={this.state.subPowerSettingMode}
            >
              Sub Power Setting Mode(use it for BT+BLE only)
            </Toggle>
          </div>
          <div className="quesstionnaire-filter-each-filter">
            {this.state.subPowerSettingMode === true ? (
              <MinPowerFieldWithSubPower
                value={this.state.minPower}
                subValue={this.state.subMinPower}
                onChange={this._onChangeMinPower}
                subOnChange={this._onChangeSubMinPower}
                errorText={this.state.minPowerError}
              />
            ) : (
              <MinPowerField
                value={this.state.minPower}
                onChange={this._onChangeMinPower}
                errorText={this.state.minPowerError}
              />
            )}
          </div>
          <div className="quesstionnaire-filter-each-filter">
            <AntennaGainField
              value={this.state.antennaGain}
              onChange={this._onChangeAntennaGain}
              errorText={this.state.antennaGainError}
            />
          </div>
        </div>
        <br />
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="normal" onClick={this._onClear}>
            Clear
          </Button>
          <Button variant="primary" onClick={this._onAdd}>
            Add
          </Button>
        </SpaceBetween>

        <br />
        {this.state.duplicateData && (
          <StatusIndicator type="error">
            {" "}
            Duplicate Entry. The combination of Technology, Specification and
            Antenna should always be unique.{" "}
          </StatusIndicator>
        )}
        <div>
          <QuestionnaireTable
            setSelectedItems={(selectedItems) =>
              this.setState({ selectedItems })
            }
            selectedItems={this.state.selectedItems}
            data={tableData}
            deleteRow={this.deleteRow}
            // editRow={this.editRow}
            jiraId={jiraId}
          />
          {this.state.tableError && (
            <StatusIndicator type="warning">
              {" "}
              {this.state.tableError}{" "}
            </StatusIndicator>
          )}
        </div>
        <br />
        <div className="d-flex justify-content-start">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={() => this._onSubmit(true)}
              loading={createDraftStatus === constants.LOADING_LOAD}
            >
              Save Draft
            </Button>
            <Button
              variant="primary"
              onClick={() => this._onSubmit(false)}
              loading={createStatus === constants.LOADING_LOAD}
            >
              Submit Testplan
            </Button>
          </SpaceBetween>
        </div>

        {/* disabling edit row feature */}
        {/* <Modal
          visible={this.state.editModal}
          header='Edit Fields'
          onDismiss={this.closeEditModal}
          size='max'
          footer={
            <div className='d-flex justify-content-center'>
              <SpaceBetween direction='horizontal' size='xs'>
                <Button variant='link' onClick={() => this.closeEditModal()}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  onClick={() => this.submitEditModal()}>
                  Save Changes
                </Button>
              </SpaceBetween>
            </div>
          }>
          <Box textAlign='center' variant='div'>
            <div className='d-flex justify-content-center'>
              <SpaceBetween direction='vertical' size='xs'>
                <SpaceBetween direction='horizontal' size='l'>
                  <Input value='Technology' disabled={true}></Input>
                  <Input value='Specification' disabled={true}></Input>
                  <Input value='Antenna' disabled={true}></Input>
                  <Input value='Internal Margin' disabled={true}></Input>
                  <Input value='Target Power' disabled={true}></Input>
                  <Input value='Min Power' disabled={true}></Input>
                  <Input value='Antenna Gain (Peak)' disabled={true}></Input>
                </SpaceBetween>
                {editRow &&
                  editRow.length &&
                  editRow.map((item, id) => (
                    <SpaceBetween direction='horizontal' size='l' key={id}>
                      <Input
                        value={editRow[id].technology}
                        disabled={true}></Input>
                      <Input value={item.specification} disabled={true}></Input>
                      <Input
                        value={editRow[id].antenna}
                        onChange={(event) => {
                          editRow[id].antenna = event.detail.value;
                          this.setState({
                            editRow,
                          });
                        }}
                        disabled></Input>
                      <Input
                        value={editRow[id].internal_margin}
                        onChange={(event) => {
                          editRow[id].internal_margin = event.detail.value;
                          this.setState({
                            editRow,
                          });
                        }}
                        disabled></Input>
                      <FormField
                        errorText={
                          editRowError && id < editRowError.length
                            ? editRowError[id].target
                            : ""
                        }>
                        <Input
                          value={editRow[id].target}
                          onChange={(event) => {
                            editRow[id].target = event.detail.value;
                            this.setState({
                              editRow,
                            });
                          }}
                        />
                      </FormField>
                      <FormField
                        errorText={
                          editRowError && id < editRowError.length
                            ? editRowError[id].minimum_power
                            : ""
                        }>
                        <Input
                          value={editRow[id].minimum_power}
                          onChange={(event) => {
                            editRow[id].minimum_power = event.detail.value;
                            this.setState({
                              editRow,
                            });
                          }}
                        />
                      </FormField>
                      <FormField
                        errorText={
                          editRowError && id < editRowError.length
                            ? editRowError[id].antenna_gain
                            : ""
                        }>
                        <Input
                          value={editRow[id].antenna_gain}
                          onChange={(event) => {
                            editRow[id].antenna_gain = event.detail.value;
                            this.setState({
                              editRow,
                            });
                          }}
                        />
                      </FormField>
                    </SpaceBetween>
                  ))}
              </SpaceBetween>
            </div>
          </Box>
        </Modal> */}
        <Modal
          visible={loadingSendMailTestPlanStatus === constants.LOADING_SUCCESS}
          header="Success"
          onDismiss={() => this.props.dispatch(resetSendMail())}
        >
          {`${sendMailTestPlanMessage}.`}
        </Modal>
        <Modal
          visible={loadingSendMailTestPlanStatus === constants.LOADING_FAIL}
          header="Failure"
          onDismiss={() => this.props.dispatch(resetSendMail())}
        >
          {`${sendMailTestPlanMessage}.`}
        </Modal>
        <Modal
          onDismiss={() => this.setState({ internalMarginModal: false })}
          visible={this.state.internalMarginModal}
          size="small"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => this.setState({ internalMarginModal: false })}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={this._handleInternalMarginSubmit}
                >
                  Save
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Edit Internal Margin"
        >
          <FormField
            label="Internal Margin"
            constraintText={"Default value : " + defaultInternalMargin + "dB"}
            errorText={this.state.editInternalMarginError}
          >
            <Input
              id={"interal margin edit"}
              placeholder="Enter Internal Margin"
              value={this.state.editInternalMargin}
              onChange={this._onChangeInternalMargin}
            ></Input>
          </FormField>
        </Modal>
        <Modal
          onDismiss={() => this.setState({ antennaModal: false })}
          visible={this.state.antennaModal}
          size="small"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => this.setState({ antennaModal: false })}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={this._handleAddAntennaSubmit}
                >
                  Add
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Add Antenna"
        >
          <FormField label="Antenna" errorText={this.state.editAntennaError}>
            <Input
              id={"antenna edit"}
              placeholder="Enter Antenna"
              value={this.state.editAntenna}
              onChange={this._onChangeAntennaInput}
            ></Input>
          </FormField>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testplanReducer: state.testplanReducer,
    downloadReducer: state.downloadReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(Questionnaire);
